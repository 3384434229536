<template>
  <div class="container-top" style="padding-bottom:0">
    <div class="pc">
      <div class="banner" :class="{ 'bg-dark': !detail.circle }">
        <div class="container">
          <div v-if="detail.circle" class="circle" :style="{ background: detail.circle }"></div>
        </div>
        <div class="banner-content">
          <div class="col-5 flex-align">
            <div>
              <div class="h2">{{ detail.title }}</div>
              <div class="body0-medium gray3" style="font-weight:400;margin-top:12px">{{ detail.subTitle }}</div>
              <a :href="detail.mainButton.link" target="_blank">
                <button
                  class="button size-16"
                  style="margin-top:32px;width:242px"
                  :class="`${detail.mainButton.class}`"
                >
                  {{ detail.mainButton.name }}
                </button>
              </a>
            </div>
          </div>
          <div class="col-7 flex" style="align-items: flex-end">
            <img :src="`/static/img/guide/${detail.img}.png`" :alt="detail.title" />
          </div>
        </div>
      </div>
      <div class="container" style="margin-top:100px;text-align: center">
        <div class="subtitle1 main" style="margin-bottom:24px">플랫폼 서비스는 앱만 있으면 되는게 아닙니다.</div>
        <div class="body1 sub2">
          플랫폼 서비스를 관리할 수 있는 사이트와<br />
          파트너들이 본인의 상품과 정보를 운영할 수 있는<br />파트너 관리자 사이트가 있어야<br />제대로 된 비즈니스를 할
          수 있습니다.
        </div>
        <img :src="img('img-solution-1@2x')" alt="플랫폼 구성" style="margin-top:60px;width:800px" />

        <div class="subtitle1 main" style="margin:120px 0 24px 0">런치팩 플랫폼 운영 솔루션은 무엇인가요?</div>
        <div class="body1 sub2">
          플랫폼 관리자와 파트너 관리자 사이트 등<br />운영에 필요한 지표정산, 회원, 상품 관리 기능을 제공하여<br />
          서비스 런칭 후 성공적인 비즈니스 운영을 돕는 플랫폼 관리자 솔루션 입니다.
        </div>
        <div>
          <img :src="img('img-notebook')" alt="관리자사이트 예시" style="width:1000px;margin-top:60px" />
        </div>
        <a href="https://demooffice.launchpack.co.kr/office/admin" target="_blank">
          <button class="button is-primary size-16" style="width:242px;height:48px">데모 사이트 바로가기</button>
        </a>
      </div>
      <div class="bg-ice" style="margin-top:120px">
        <div class="container">
          <div class="flex">
            <img :src="img('img-solution-3@2x')" alt="플랫폼 서비스 전용 솔루션이 필요한 이유" style="width:580px" />
            <div>
              <div class="text-center">
                <div class="subtitle1 main">플랫폼 서비스 전용 솔루션이 필요한 이유</div>
                <div class="body1 sub2" style="margin-top:24px">
                  플랫폼 서비스 창업을 준비하시는 분들이 항상 겪게되는 문제가 있습니다.<br />
                  처음 서비스를 기획할 때 생각했던 부분만으로는 실제로 서비스를 운영하고<br />
                  관리할 수 없다는 것입니다. 처음 기획한 내용은 빙산의 일각이고<br />
                  런칭을 위해 추가로 고려해야하는 사항은 끝도 없이 발생하게 됩니다.
                </div>
              </div>
              <div class="text-center" style="margin-top: 240px;color:white">
                <div class="subtitle1">런치팩 솔루션을 이용해야 하는 이유</div>
                <div class="body1" style="margin-top:40px">
                  현재 시장을 선도하는 플랫폼 서비스들의 특징은<br />
                  전문 개발팀과 디자이너팀을 보유하고 있다는 것입니다.<br /><br />

                  그만큼 플랫폼 서비스는 전문성이 중요한 분야이기 때문에<br />
                  런치팩을 통해 창업하는 것이 일반 개발자 채용보다<br />
                  훨씬 빠르고 정확하게 전문적인 서비스를 만드는 데 유리합니다.<br /><br />

                  그동안 다양한 플랫폼 서비스를 운영한 노하우를 살려<br />
                  창업자가 미처 고려하지 못했던 고급 운영 기능들을<br />
                  풀스펙으로 제공해드립니다.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-gray">
        <img :src="img('img-solution-wave@2x')" style="width:100%" />
        <div class="container" style="padding:120px 0">
          <div class="size-28 main">
            서비스 뿐만 아니라 관리자 사이트까지 제공되어<br />
            <b>플랫폼 운영에 필요한 관리 기능을 고민하고 제작하느라 추가개발 할 필요가 없어요.</b>
          </div>

          <div class="margin-top-60">
            <div class="section-grid-info">
              <img src="/static/img/notebook.png" />
              <div class="section-grid-info-item">
                <div
                  v-for="(menuItem, idx) in menu"
                  :key="'menuItem-' + idx"
                  class="flex-align bg-white"
                  style="padding: 16px"
                >
                  <img
                    :src="icon(menuItem.icon)"
                    class="svg-sub2"
                    style="margin-right: 8px; width: 20px; height: 20px"
                  />
                  <div class="body4 sub2">{{ menuItem.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section-4">
        <div class="container" style="align-items: flex-start">
          <div class="col-12 text-center">
            <div class="section-title">플랫폼파이 테마로</div>
            <div class="section-title-bold">어떤 서비스를 만들 수 있나요?</div>
          </div>
          <div class="col-12" style="border-radius: 12px;overflow: hidden">
            <div class="flex platform-box">
              <div class="flex-align">
                <div class="base-platform">
                  <div class="subtitle3 main margin-bottom-20">수요자와 공급자가<br />전화로 연결되는 플랫폼</div>
                  <div class="margin-bottom-20">
                    <div class="h8">
                      <span class="primary">{{ products.call.discount_rate }}%</span>
                      <span class="main margin-left-4">{{ products.call.sale_price | currencyNum }}</span>
                      <span class="body4 sub2">원/월~</span>
                    </div>
                    <div class="flex-align" style="margin-top:8px;gap:6px">
                      <div class="flex-align" style="gap:2px">
                        <img src="/static/icon/u_star.svg" class="svg-sub3" style="width:14px" />
                        <div class="body6 sub3">{{ products.call.rate | currencyNum }}</div>
                      </div>
                      <div class="flex-align" style="gap:2px">
                        <img src="/static/icon/u_eye.svg" class="svg-sub3" style="width:14px" />
                        <div class="body6 sub3">{{ products.call.visit | currencyNum }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="body4 sub3 margin-bottom-8"># 전문가 전화연결 플랫폼</div>
                  <div class="body4 sub3 margin-bottom-8"># 바로 배송 가능한 옷집 플랫폼</div>
                  <div class="body4 sub3 margin-bottom-8"># 소개팅하기 좋은 카페 전화연결 플랫폼</div>
                  <div class="body4 sub3 margin-bottom-48"># 프리미엄 고메 식당 전화연결 플랫폼</div>
                  <div class="flex-align">
                    <button
                      class="button is-primary body2-medium"
                      style="width:220px"
                      @click="routeTrial(`theme_detail?id=${products.call.id}`)"
                    >
                      무료체험
                    </button>
                    <div
                      class="flex-align unselect"
                      style="padding:0 40px"
                      @click="clickPreview('https://lpthemecall.launchpack.co.kr/home')"
                    >
                      <div class="sub2 body2-medium" style="margin-right:4px">미리보기</div>
                      <img src="/static/icon/u_arrow-up-right.svg" class="svg-sub2" />
                    </div>
                  </div>
                </div>
              </div>
              <img
                class="unselect"
                @click="routeTrial(`theme_detail?id=${products.call.id}`)"
                src="/static/img/home/product_call.jpg"
                style="height:450.5px"
              />
            </div>

            <div class="flex-align platform-box">
              <img
                class="unselect"
                @click="routeTrial(`theme_detail?id=${products.delivery.id}`)"
                src="/static/img/home/product_delivery.jpg"
                style="height:450.5px"
              />
              <div class="flex-align">
                <div class="base-platform">
                  <div class="subtitle3 main margin-bottom-16">상품을 택배 배송으로<br />받는 플랫폼</div>
                  <div class="margin-bottom-20">
                    <div class="h8">
                      <span class="primary">{{ products.delivery.discount_rate }}%</span>
                      <span class="main margin-left-4">{{ products.delivery.sale_price | currencyNum }}</span>
                      <span class="body4 sub2">원/월~</span>
                    </div>
                    <div class="flex-align" style="margin-top:8px;gap:6px">
                      <div class="flex-align" style="gap:2px">
                        <img src="/static/icon/u_star.svg" class="svg-sub3" style="width:14px" />
                        <div class="body6 sub3">{{ products.delivery.rate | currencyNum }}</div>
                      </div>
                      <div class="flex-align" style="gap:2px">
                        <img src="/static/icon/u_eye.svg" class="svg-sub3" style="width:14px" />
                        <div class="body6 sub3">{{ products.delivery.visit | currencyNum }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="body4 sub3 margin-bottom-8"># 힙한 스트릿 브랜드들을 한 곳에 모은 쇼핑 플랫폼</div>
                  <div class="body4 sub3 margin-bottom-8"># 비건 빵 주문 배송 플랫폼</div>
                  <div class="body4 sub3 margin-bottom-48"># 중고 레저 캠핑 용품 플랫폼</div>
                  <div class="flex-align">
                    <button
                      class="button is-primary body2-medium"
                      style="width:220px"
                      @click="routeTrial(`theme_detail?id=${products.delivery.id}`)"
                    >
                      무료체험
                    </button>
                    <div
                      class="flex-align unselect"
                      style="padding:0 40px"
                      @click="clickPreview('https://lpthemedelivery.launchpack.co.kr/home')"
                    >
                      <div class="sub2 body2-medium" style="margin-right:4px">미리보기</div>
                      <img src="/static/icon/u_arrow-up-right.svg" class="svg-sub2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex-align platform-box">
              <div class="base-platform">
                <div class="subtitle3 main margin-bottom-16">공간이나 서비스를<br />시간단위로 예약하는 플랫폼</div>
                <div class="margin-bottom-20">
                  <div class="h8">
                    <span class="primary">{{ products.reservation.discount_rate }}%</span>
                    <span class="main margin-left-4">{{ products.reservation.sale_price | currencyNum }}</span>
                    <span class="body4 sub2">원/월~</span>
                  </div>
                  <div class="flex-align" style="margin-top:8px;gap:6px">
                    <div class="flex-align" style="gap:2px">
                      <img src="/static/icon/u_star.svg" class="svg-sub3" style="width:14px" />
                      <div class="body6 sub3">{{ products.reservation.rate | currencyNum }}</div>
                    </div>
                    <div class="flex-align" style="gap:2px">
                      <img src="/static/icon/u_eye.svg" class="svg-sub3" style="width:14px" />
                      <div class="body6 sub3">{{ products.reservation.visit | currencyNum }}</div>
                    </div>
                  </div>
                </div>
                <div class="body4 sub3 margin-bottom-8"># 분위기 좋은 카페를 과제용 미팅룸으로 예약하는 플랫폼</div>
                <div class="body4 sub3 margin-bottom-8"># 방문 홈트레이닝 예약 플랫폼</div>
                <div class="body4 sub3 margin-bottom-48"># 1:1 뷰티샵 예약 플랫폼</div>

                <div class="flex-align">
                  <button
                    class="button is-primary body2-medium"
                    style="width:220px"
                    @click="routeTrial(`theme_detail?id=${products.reservation.id}`)"
                  >
                    무료체험
                  </button>
                  <div
                    class="flex-align unselect"
                    style="padding:0 40px"
                    @click="clickPreview('https://lpthemereservation.launchpack.co.kr/home')"
                  >
                    <div class="sub2 body2-medium" style="margin-right:4px">미리보기</div>
                    <img src="/static/icon/u_arrow-up-right.svg" class="svg-sub2" />
                  </div>
                </div>
              </div>
              <img
                class="unselect"
                @click="routeTrial(`theme_detail?id=${products.reservation.id}`)"
                src="/static/img/home/product_reservation.jpg"
                style="height:450.5px"
              />
            </div>

            <div class="platform-box flex-align" style="border:none">
              <img
                class="unselect"
                @click="routeTrial(`theme_detail?id=${products.booking.id}`)"
                src="/static/img/home/product_booking.jpg"
                style="height:450.5px"
              />
              <div class="base-platform">
                <div class="subtitle3 main margin-bottom-16">공간이나 서비스를<br />기간단위로 예약하는 플랫폼</div>
                <div class="margin-bottom-20">
                  <div class="h8">
                    <span class="primary">{{ products.booking.discount_rate }}%</span>
                    <span class="main margin-left-4">{{ products.booking.sale_price | currencyNum }}</span>
                    <span class="body4 sub2">원/월~</span>
                  </div>
                  <div class="flex-align" style="margin-top:8px;gap:6px">
                    <div class="flex-align" style="gap:2px">
                      <img src="/static/icon/u_star.svg" class="svg-sub3" style="width:14px" />
                      <div class="body6 sub3">{{ products.booking.rate | currencyNum }}</div>
                    </div>
                    <div class="flex-align" style="gap:2px">
                      <img src="/static/icon/u_eye.svg" class="svg-sub3" style="width:14px" />
                      <div class="body6 sub3">{{ products.booking.visit | currencyNum }}</div>
                    </div>
                  </div>
                </div>
                <div class="body4 sub3 margin-bottom-8"># 제주도 한달살기 임대 플랫폼</div>
                <div class="body4 sub3 margin-bottom-8"># 힙한 숙박예약 플랫폼</div>
                <div class="body4 sub3 margin-bottom-8"># 여행 기간 반려동물 돌보미 플랫폼</div>
                <div class="body4 sub3 margin-bottom-48"># 사무실 청소 서비스 플랫폼</div>
                <div class="flex-align">
                  <button
                    class="button is-primary body2-medium"
                    style="width:220px"
                    @click="routeTrial(`theme_detail?id=${products.booking.id}`)"
                  >
                    무료체험
                  </button>
                  <div
                    class="flex-align unselect"
                    style="padding:0 40px"
                    @click="clickPreview('https://lpthemebooking.launchpack.co.kr/home')"
                  >
                    <div class="sub2 body2-medium" style="margin-right:4px">미리보기</div>
                    <img src="/static/icon/u_arrow-up-right.svg" class="svg-sub2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section-7" style="padding:100px 0">
        <div class="container" style="flex-direction: column">
          <div style="text-align: center" class="margin-bottom-40">
            <div class="subtitle2 main">이외에도 공급자와 수요자 사이에 아래와 같은 주문 형식이 가능해요!</div>
          </div>
          <div class="grid" style="grid-template-columns: repeat(6, 1fr);grid-column-gap:20px;width:100%">
            <div
              class="position-relative"
              :class="{ unselect: item.subTitle !== 'Maintenance' }"
              v-for="(item, idx) in extras"
              :key="'card-extra-' + idx"
              @click="clickExtra(item)"
            >
              <div class="card-extra flex-center margin-bottom-8">
                <div>
                  <div class="flex-justify">
                    <div class="extras-icon-bg flex-center">
                      <img :src="`/static/img/home/${item.icon}.svg`" />
                    </div>
                  </div>
                  <div style="margin-top:12px" class="text-center">
                    <div class="main size-15 weight-500 margin-bottom-4">
                      {{ item.title }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="body5 sub3 text-center" style="word-break:keep-all;padding:0 12px">{{ item.desc }}</div>
              <div
                class="body6-bold position-absolute sub-style"
                :class="[item.subTitle === 'Prime' ? 'sub-prime' : 'sub-ex']"
              >
                {{ item.subTitle }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile">
      <div class="banner" :class="{ 'bg-dark': !detail.circle }">
        <div class="container">
          <div v-if="detail.circle" class="circle" :style="{ background: detail.circle }"></div>
        </div>
        <div class="banner-content-mobile">
          <div class="h6">{{ detail.title }}</div>
          <div class="body4" style="margin-top:4px">{{ detail.subTitle }}</div>
          <a :href="detail.mainButton.link" target="_blank">
            <button class="button body5-medium is-primary" style="margin-top:16px;width:180px">
              {{ detail.mainButton.name }}
            </button>
          </a>
        </div>
      </div>
      <div class="container" style="margin-top:40px;text-align: center">
        <div class="body0-medium main" style="margin-bottom:16px">
          플랫폼 서비스는 앱만 있으면<br />되는게 아닙니다.
        </div>
        <div class="body4 sub2">
          플랫폼 서비스를 관리할 수 있는 사이트와<br />
          파트너들이 본인의 상품과 정보를 운영할 수 있는<br />파트너 관리자 사이트가 있어야<br />제대로 된 비즈니스를 할
          수 있습니다.
        </div>
        <img :src="img('img-solution-1@2x')" alt="플랫폼 구성" style="margin-top:32px;width:100%" />

        <div class="body0-medium main" style="margin:40px 0 16px 0">런치팩 플랫폼 운영 솔루션은<br />무엇인가요?</div>
        <div class="body4 sub2">
          플랫폼 관리자와 파트너 관리자 사이트 등<br />운영에 필요한 지표정산, 회원, 상품 관리 기능을 제공하여<br />
          서비스 런칭 후 성공적인 비즈니스 운영을 돕는<br />플랫폼 관리자 솔루션 입니다.
        </div>
        <div><img :src="img('img-notebook')" alt="관리자사이트 예시" style="width:100%;margin-top:32px" /></div>
        <a href="https://demooffice.launchpack.co.kr/office/admin" target="_blank">
          <button class="button is-primary body2-medium" style="width:180px">데모 사이트 바로가기</button>
        </a>
      </div>
      <div class="container" style="margin-top:60px">
        <div class="text-center">
          <div class="body0-medium main">플랫폼 서비스 전용 솔루션이<br />필요한 이유</div>
          <div class="body4 sub2" style="margin-top:16px">
            플랫폼 서비스 창업을 준비하시는 분들이 항상 겪게되는 문제가 있습니다. 처음 서비스를 기획할 때 생각했던
            부분만으로는 실제로 서비스를 운영하고 관리할 수 없다는 것입니다. 처음 기획한 내용은 빙산의 일각이고 런칭을
            위해 추가로 고려해야하는 사항은 끝도 없이 발생하게 됩니다.
          </div>
        </div>
      </div>
      <div class="bg-ice" style="margin-top:40px;padding-bottom:24px">
        <div class="container">
          <img :src="img('img-solution-3@2x')" alt="플랫폼 서비스 전용 솔루션이 필요한 이유" style="width:100%" />
          <div class="text-center" style="margin-top: 24px;color:white">
            <div class="body0-medium">런치팩 솔루션을 이용해야 하는 이유</div>
            <div class="body4" style="margin-top:24px;word-break:break-all">
              <p>현재 시장을 선도하는 플랫폼 서비스들의 특징은 전문 개발팀과 디자이너팀을 보유하고 있다는 것입니다.</p>
              <p>
                그만큼 플랫폼 서비스는 전문성이 중요한 분야이기 때문에 런치팩을 통해 창업하는 것이 일반 개발자 채용보다
                훨씬 빠르고 정확하게 전문적인 서비스를 만드는 데 유리합니다.
              </p>
              <p>
                그동안 다양한 플랫폼 서비스를 운영한 노하우를 살려 창업자가 미처 고려하지 못했던 고급 운영 기능들을
                풀스펙으로 제공해드립니다.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="container-gray">
        <img :src="img('img-solution-wave@2x')" style="width:100%" />
        <div class="container" style="padding-top:60px;padding-bottom:60px">
          <div class="margin-bottom-40 main mo-section-title">
            플랫폼파이로 만들면<br />
            <b
              >서비스 뿐만 아니라<br />관리자 사이트까지 제공되어<br />
              비지니스 운영이 가능합니다.</b
            >
          </div>

          <div class="grid margin-bottom-48" style="grid-template-columns: repeat(2, 1fr); gap: 8px 10px; width: 100%;">
            <div
              v-for="(menuItem, idx) in menu"
              :key="'menuItem-' + idx"
              class="func-item-mo flex-align"
              style="padding: 16px"
            >
              <img :src="icon(menuItem.icon)" class="svg-sub2" style="margin-right: 8px; width: 20px; height: 20px" />
              <div class="body4 sub2">{{ menuItem.name }}</div>
            </div>
          </div>
          <img src="/static/img/notebook.png" style="width:100%" />
        </div>
      </div>

      <div class="section-4">
        <div class="container">
          <div class="margin-bottom-24 main mo-section-title-bold">
            <span class="mo-section-title">플랫폼파이 테마로</span><br />어떤 서비스를 만들 수 있나요?
          </div>
          <div class="pack-item col-12">
            <img
              class="unselect"
              @click="routeTrial(`theme_detail?id=${products.call.id}`)"
              src="/static/img/home/product_call.jpg"
              style="width:100%"
            />
            <div style="margin-top:16px">
              <div class="body2-bold main margin-bottom-12">수요자와 공급자가<br />전화로 연결되는 플랫폼</div>
              <div class="margin-bottom-12">
                <div class="body4-bold">
                  <span class="primary">{{ products.call.discount_rate }}%</span>
                  <span class="main margin-left-4">{{ products.call.sale_price | currencyNum }}</span>
                  <span class="body6 sub2">원/월~</span>
                </div>
                <div class="flex-align" style="margin-top:8px;gap:6px">
                  <div class="flex-align" style="gap:2px">
                    <img src="/static/icon/u_star.svg" class="svg-sub3" style="width:13px" />
                    <div class="body7 sub3">{{ products.call.rate | currencyNum }}</div>
                  </div>
                  <div class="flex-align" style="gap:2px">
                    <img src="/static/icon/u_eye.svg" class="svg-sub3" style="width:13px" />
                    <div class="body7 sub3">{{ products.call.visit | currencyNum }}</div>
                  </div>
                </div>
              </div>
              <div class="body5 sub3 margin-bottom-4"># 전문가 전화연결 플랫폼</div>
              <div class="body5 sub3 margin-bottom-4"># 바로 배송 가능한 옷집 플랫폼</div>
              <div class="body5 sub3 margin-bottom-4"># 소개팅하기 좋은 카페 전화연결 플랫폼</div>
              <div class="body5 sub3 margin-bottom-24"># 프리미엄 고메 식당 전화연결 플랫폼</div>
              <div class="flex-between" style="gap:16px">
                <button
                  class="button is-primary body4-medium"
                  style="flex:1;height:40px"
                  @click="routeTrial(`theme_detail?id=${products.call.id}`)"
                >
                  무료체험
                </button>
                <button
                  class="button sub2 body4-medium"
                  style="flex:1;height:40px"
                  @click="clickPreview('https://lpthemecall.launchpack.co.kr/home')"
                >
                  미리보기
                </button>
              </div>
            </div>
          </div>
          <div class="pack-item col-12">
            <img
              class="unselect"
              @click="routeTrial(`theme_detail?id=${products.delivery.id}`)"
              src="/static/img/home/product_delivery.jpg"
              style="width:100%"
            />
            <div style="margin-top:16px">
              <div class="body2-bold main margin-bottom-12">상품을 택배 배송으로 받는 플랫폼</div>
              <div class="margin-bottom-12">
                <div class="body4-bold">
                  <span class="primary">{{ products.delivery.discount_rate }}%</span>
                  <span class="main margin-left-4">{{ products.delivery.sale_price | currencyNum }}</span>
                  <span class="body6 sub2">원/월~</span>
                </div>
                <div class="flex-align" style="margin-top:8px;gap:6px">
                  <div class="flex-align" style="gap:2px">
                    <img src="/static/icon/u_star.svg" class="svg-sub3" style="width:13px" />
                    <div class="body7 sub3">{{ products.delivery.rate | currencyNum }}</div>
                  </div>
                  <div class="flex-align" style="gap:2px">
                    <img src="/static/icon/u_eye.svg" class="svg-sub3" style="width:13px" />
                    <div class="body7 sub3">{{ products.delivery.visit | currencyNum }}</div>
                  </div>
                </div>
              </div>
              <div class="body5 sub3 margin-bottom-4">#힙한 스트릿 브랜드들을 한 곳에 모은 쇼핑 플랫폼</div>
              <div class="body5 sub3 margin-bottom-4">#비건 빵 주문 배송 플랫폼</div>
              <div class="body5 sub3 margin-bottom-24">#중고 레저 캠핑 용품 플랫폼</div>
              <div class="flex-between" style="gap:16px">
                <button
                  class="button is-primary body4-medium"
                  style="flex:1;height:40px"
                  @click="routeTrial(`theme_detail?id=${products.delivery.id}`)"
                >
                  무료체험
                </button>
                <button
                  class="button sub2 body4-medium"
                  style="flex:1;height:40px"
                  @click="clickPreview('https://lpthemedelivery.launchpack.co.kr/home')"
                >
                  미리보기
                </button>
              </div>
            </div>
          </div>
          <div class="pack-item col-12">
            <img
              class="unselect"
              @click="routeTrial(`theme_detail?id=${products.call.id}`)"
              src="/static/img/home/product_reservation.jpg"
              style="width:100%"
            />
            <div style="margin-top:16px">
              <div class="body2-bold main margin-bottom-12">공간이나 서비스를<br />시간단위로 예약하는 플랫폼</div>
              <div class="margin-bottom-12">
                <div class="body4-bold">
                  <span class="primary">{{ products.reservation.discount_rate }}%</span>
                  <span class="main margin-left-4">{{ products.reservation.sale_price | currencyNum }}</span>
                  <span class="body6 sub2">원/월~</span>
                </div>
                <div class="flex-align" style="margin-top:8px;gap:6px">
                  <div class="flex-align" style="gap:2px">
                    <img src="/static/icon/u_star.svg" class="svg-sub3" style="width:13px" />
                    <div class="body7 sub3">{{ products.reservation.rate | currencyNum }}</div>
                  </div>
                  <div class="flex-align" style="gap:2px">
                    <img src="/static/icon/u_eye.svg" class="svg-sub3" style="width:13px" />
                    <div class="body7 sub3">{{ products.reservation.visit | currencyNum }}</div>
                  </div>
                </div>
              </div>
              <div class="body5 sub3 margin-bottom-4">#분위기 좋은 카페를 과제용 미팅룸으로 예약하는 플랫폼</div>
              <div class="body5 sub3 margin-bottom-4">#방문 홈트레이닝 예약 플랫폼</div>
              <div class="body5 sub3 margin-bottom-24">#1:1 뷰티샵 예약 플랫폼</div>
              <div class="flex-between" style="gap:16px">
                <button
                  class="button is-primary body4-medium"
                  style="flex:1;height:40px"
                  @click="routeTrial(`theme_detail?id=${products.reservation.id}`)"
                >
                  무료체험
                </button>
                <button
                  class="button sub2 body4-medium"
                  style="flex:1;height:40px"
                  @click="clickPreview('https://lpthemereservation.launchpack.co.kr/home')"
                >
                  미리보기
                </button>
              </div>
            </div>
          </div>
          <div class="pack-item col-12" style="border:none">
            <img
              class="unselect"
              @click="routeTrial(`theme_detail?id=${products.booking.id}`)"
              src="/static/img/home/product_booking.jpg"
              style="width:100%"
            />
            <div style="margin-top:16px">
              <div class="body2-bold main margin-bottom-12">공간이나 서비스를<br />기간단위로 예약하는 플랫폼</div>
              <div class="margin-bottom-12">
                <div class="body4-bold">
                  <span class="primary">{{ products.booking.discount_rate }}%</span>
                  <span class="main margin-left-4">{{ products.booking.sale_price | currencyNum }}</span>
                  <span class="body6 sub2">원/월~</span>
                </div>
                <div class="flex-align" style="margin-top:8px;gap:6px">
                  <div class="flex-align" style="gap:2px">
                    <img src="/static/icon/u_star.svg" class="svg-sub3" style="width:13px" />
                    <div class="body7 sub3">{{ products.booking.rate | currencyNum }}</div>
                  </div>
                  <div class="flex-align" style="gap:2px">
                    <img src="/static/icon/u_eye.svg" class="svg-sub3" style="width:13px" />
                    <div class="body7 sub3">{{ products.booking.visit | currencyNum }}</div>
                  </div>
                </div>
              </div>
              <div class="body5 sub3 margin-bottom-4">#제주도 한달살기 임대 플랫폼</div>
              <div class="body5 sub3 margin-bottom-4">#힙한 숙박예약 플랫폼 #여행 기간 반려동물 돌보미 플랫폼</div>
              <div class="body5 sub3 margin-bottom-24">#사무실 청소 서비스 플랫폼</div>
              <div class="flex-between" style="gap:16px">
                <button
                  class="button is-primary body4-medium"
                  style="flex:1;height:40px"
                  @click="routeTrial(`theme_detail?id=${products.call.id}`)"
                >
                  무료체험
                </button>
                <button
                  class="button sub2 body4-medium"
                  style="flex:1;height:40px"
                  @click="clickPreview('https://lpthemebooking.launchpack.co.kr/home')"
                >
                  미리보기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section-7">
        <div class="container">
          <div class="body0-medium main margin-bottom-24">
            이외에도 공급자와 수요자 사이에<br />아래와 같은 주문 형식이 가능해요!
          </div>
          <div
            class="grid"
            style="grid-template-columns: repeat(2, 1fr);grid-row-gap:24px;grid-column-gap:12px;width:100%"
          >
            <div
              class="position-relative"
              :class="{ unselect: item.subTitle !== 'Maintenance' }"
              v-for="(item, idx) in extras"
              :key="'card-extra-' + idx"
              @click="clickExtra(item)"
            >
              <div class="col-12">
                <div class="card-extra-mo">
                  <div class="flex-justify">
                    <div class="flex-center">
                      <img :src="`/static/img/home/${item.icon}.svg`" />
                    </div>
                  </div>
                  <div style="margin-top:14px" class="text-center">
                    <div class="main body5-medium">
                      {{ item.title }}
                    </div>
                  </div>
                </div>
                <div class="body5 text-center sub3" style="word-break:keep-all;padding:0 12px;margin-top:8px">
                  {{ item.desc }}
                </div>
              </div>
              <div
                class="body7 position-absolute sub-style"
                style="padding:2px 8px 4px"
                :class="[item.subTitle === 'Prime' ? 'sub-prime' : 'sub-ex']"
              >
                {{ item.subTitle }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SolutionInfo',
  created() {
    this.getProduct();
  },
  data() {
    return {
      detail: {
        title: '플랫폼 운영 솔루션',
        subTitle: '플랫폼 운영에 필요한 통합 솔루션',
        img: 'img_page_api',
        mainButton: {
          name: '데모 사이트 바로가기',
          class: 'is-primary',
          link: 'https://demooffice.launchpack.co.kr/office/admin',
        },
      },
      packs: [
        {
          title: '기본',
          list: [
            '대쉬보드',
            '서비스 관리',
            '회원 관리',
            '파트너 관리',
            '상품 관리',
            '기본 게시판 관리',
            '주문전환 관리',
            '필터/정렬 관리',
            '지역/카테고리 관리',
            '후기/문의 관리',
            '고객센터 관리',
          ],
        },
        {
          title: '주문전환',
          list: [
            '플랫폼서비스의 판매 상품 커머스',
            '장소 예약 커머스',
            '입점형 파트너 정산 커머스',
            '디지털 컨텐츠 다운로드 커머스',
            '모임 신청 커머스',
            '행사 예매 커머스\n(좌석 지정 기능은 미포함)',
            '사용권 쿠폰 커머스',
          ],
        },
      ],
      products: {
        call: {
          id: 0,
          sale_price: 600000,
          discount_rate: 20,
          rate: 4.0,
          visit: 0,
        },
        reservation: {
          id: 0,
          sale_price: 600000,
          discount_rate: 20,
          rate: 4.0,
          visit: 0,
        },
        booking: {
          id: 0,
          sale_price: 600000,
          discount_rate: 20,
          rate: 4.0,
          visit: 0,
        },
        delivery: {
          id: 0,
          sale_price: 600000,
          discount_rate: 20,
          rate: 4.0,
          visit: 0,
        },
      },
      extras: [
        {
          subTitle: 'Prime',
          title: '견적요청 및 견적서 발송 팩',
          icon: 'color_file-alt',
          desc: '#이사 견적 플랫폼',
        },
        {
          subTitle: 'Platformfy',
          title: '클릭 시 링크이동 팩',
          icon: 'color_external-link-alt',
          desc: '# 상품을 가격 비교 후 사이트 이동 플랫폼',
        },
        {
          subTitle: 'Prime',
          title: '로컬배달 주문 팩',
          icon: 'draw_motorcycle',
          desc: '# 음식점 배달 플랫폼',
        },
        {
          subTitle: 'Prime',
          title: '자료컨텐츠 다운로드 팩',
          icon: 'color_download-alt',
          desc: '# 온라인 자료실 플랫폼',
        },
        {
          subTitle: 'Platformfy',
          title: '커뮤니티 팩',
          icon: 'color_megaphone',
          desc: '# 온라인 커뮤니티 플랫폼',
        },
        {
          subTitle: 'Platformfy',
          title: '상담신청 팩',
          icon: 'color_comment-dots',
          desc: '# 병원 상담 플랫폼',
        },
      ],
      menu: [
        {
          icon: 'u:panel-add',
          name: '대쉬보드',
        },
        {
          icon: 'u:phone-volume',
          name: '전화연결',
        },
        {
          icon: 'u:comment-dots',
          name: '고객센터',
        },
        {
          icon: 'u:setting',
          name: '서비스 관리',
        },
        {
          icon: 'u:filter',
          name: '필터/정렬',
        },
        {
          icon: 'u:fast-mail',
          name: '메일/SNS 전송',
        },
        {
          icon: 'u:clipboard-notes',
          name: '게시판 관리',
        },
        {
          icon: 'u:money-withdraw',
          name: '정산 관리',
        },
        {
          icon: 'u:box',
          name: '상품 관리',
        },
        {
          icon: 'u:user',
          name: '회원 관리',
        },
        {
          icon: 'u:apps',
          name: '카테고리',
        },
        {
          icon: 'u:edit-alt',
          name: '후기/문의',
        },
        {
          icon: 'u:users-alt',
          name: '파트너 관리',
        },
        {
          icon: 'u:calender',
          name: '예약 관리',
        },
        {
          icon: 'u:file-check',
          name: '주문관리',
        },
      ],
    };
  },
  methods: {
    clickExtra(item) {
      if (item.link) {
        this.routerPush(item.link);
      }
    },
    routeTrial(url) {
      const a = document.createElement('a');
      // a.href = `https://pfa.platformfy.com/${url}`;
      a.href = `https://platformfy.com/${url}`;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      a.parentNode.removeChild(a);
    },
    clickPreview(url) {
      /*window.name = 'preview_parent';
        let popup = window.open('', 'previewPopup', 'width=360, height=640, top=30, left=30, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=yes, scrollbar=no');
        popup.location.href = url;*/
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      a.parentNode.removeChild(a);
    },
    img(img) {
      return `/static/img/solution/${img}.png`;
    },
    icon(img) {
      return `/static/icon/${img.replace(':', '_')}.svg`;
    },
    getProduct() {
      this.$axios
        .get('user/0/mapping/product', {
          params: {
            category: 59,
            fields: 'id,price,rate,visit,params',
            ordering: 'priority',
          },
        })
        .then((res) => {
          res.data.data.forEach((i) => {
            let key = i.params.find((p) => p.name === 'key').value;
            let obj = this.products[key];
            obj.id = i.id;
            obj.sale_price = i.price.sale_price;
            obj.discount_rate = i.price.discount_rate;
            obj.rate = i.rate;
            obj.visit = i.visit;
          });
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.bg-white
  background-color white

.bg-ice
  background linear-gradient(180deg, #fff, #dbdde5, #657090, #152457)

.lp-divider-gray1
  flex 1

.pack
  margin auto

.box-pack
  display flex
  justify-content center
  margin-top 40px
  div
    width 320px
    white-space pre-line

.check
.check-mobile
  width 20px
  height 20px
  background $primary
  color white
  border-radius 100%
  line-height 20px
  font-size 15px
  text-align center
  margin-right 12px
  display flex
  justify-content center
  align-items center

.check-mobile
  width 18px
  height 18px
  line-height 18px
  font-size 14px
  margin-right 8px

.banner
  background-color $gray4
  height 440px
  position relative
  overflow hidden
  color $main
.banner-content
  position absolute
  width $page-width
  left 50%
  transform translateX(-50%)
  display flex
  height 100%
  z-index 1
.circle
  opacity 0.15
  filter blur(300px)
  -webkit-backdrop-filter blur(300px)
  backdrop-filter blur(300px)
  position absolute
  top -50px
  left -603px
  width $page-width
  height $page-width

.bg-dark
  background linear-gradient(132.62deg, #242428 34.49%, #4F4F4F 88.8%)
  color white

.pack-title
  margin 80px 0 40px 0

.container-gray
  margin-top -1px
  background-color $gray3

.section-grid-info
  display grid
  grid-template-columns repeat(2, 1fr)
  grid-gap 0px 80px

.section-grid-info-item
  display grid
  grid-template-columns repeat(3, 1fr)
  grid-gap 12px

.section-4
  padding 112px 0

.func-item
  padding 16px
  background-color white

.func-item-mo
  padding 9px 16px
  background-color white

.base-platform
  flex 1
  background-color white
  padding 40px 52px

.platform-box
  overflow hidden
  padding 100px 0
  border-bottom 1px solid $gray2

.section-7
  padding 150px 0
  background-color $gray4

.card-extra
  background-color white
  border-radius 8px
  padding 36px 0 20px 0
  border 1px solid $gray2

.card-extra:hover
  background-color $gray4
.card-extra-icon-bg
  background-color white
  border-radius 12px
  width 60px
  height 60px
  text-align center
  line-height 60px
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.04)

.card-extra-mo
  background-color white
  border-radius 8px
  padding 40px 10px 24px
  border 1px solid $gray2

.sub-style
  top 8px
  left 8px
  border-radius 50px
  padding 3px 8px 4px

.sub-prime
  color $primary
  background-color black

.sub-ex
  color white
  background-color $primary

.pack-item
  padding 16px 0 36px
  border-bottom 1px solid $gray1

@media (max-width:1024px)
  .banner
    height 160px
    padding 0
    position relative
  .banner-content-mobile
    display block
    text-align center
    width 100%
    top 50%
    left 50%
    position absolute
    transform translate(-50%, -50%)
  .circle
    width 370px
    height 370px
    top -44px
    left -50px
  p
    margin-bottom 12px
  .section-4
  .section-7
    padding 60px 0
    height initial
</style>
